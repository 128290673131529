import { Button, Checkbox, Form, Input, Select, Space, Tooltip } from "antd";
import { CustomerRule, CustomerRuleCondition, CustomerRuleOutcome, MethodName, opportunityValues, RuleAction, RuleCondition, RuleFor } from "../../../../dto/admin-rule-models";
import TextArea from "antd/es/input/TextArea";
import { DateTimePicker } from "@mantine/dates";
import dayjs from "dayjs";

type RuleTabProps = {
  rule: CustomerRule
  setRule: (rule: CustomerRule) => void
  tabPane: RuleFor
}

const CheckboxGroup = Checkbox.Group;
const { Option } = Select;

export const RuleTab = ({ rule, setRule, tabPane }: RuleTabProps) => {



  const addCondition = (ruleFor: RuleFor) => {
    const newConditions = [...rule.conditions, {
      feId: crypto.randomUUID(),
      condition: RuleCondition.None,
      value: '',
      propertyName: '',
      methodNames: rule.conditions.filter(x => x.ruleFor == ruleFor)[0]?.methodNames,
      ruleFor: ruleFor
    }];

    const updatedRule = {
      ...rule,
      conditions: newConditions
    };

    setRule(updatedRule);
  }

  const removeCondition = (feId: string) => {
    const updatedConditions = rule.conditions.filter(condition => condition.feId != feId);
    const updatedRule = {
      ...rule,
      conditions: updatedConditions
    }
    setRule(updatedRule);
  }

  const addOutcome = (ruleFor: RuleFor) => {
    const newOutcomes = [...rule.outcomes, {
      feId: crypto.randomUUID(),
      action: RuleAction.None,
      actionValue: '',
      methodNames: rule.conditions.filter(x => x.ruleFor == ruleFor)[0]?.methodNames,
      ruleFor: ruleFor
    }];
    const updatedRule = {
      ...rule,
      outcomes: newOutcomes
    }

    setRule(updatedRule);
  }

  const removeOutcome = (feId: string) => {
    const updatedOutcomes = rule.outcomes.filter(outcome => outcome.feId != feId);
    const updatedRule = {
      ...rule,
      outcomes: updatedOutcomes
    }

    setRule(updatedRule);
  }

  const updateCondition = (condition: CustomerRuleCondition) => {
    const updatedConditions = rule.conditions.map(c => c.feId == condition.feId ? condition : c);
    const updatedRule = {
      ...rule,
      conditions: updatedConditions
    }
    setRule(updatedRule);
  }

  const updateOutcome = (outcome: CustomerRuleOutcome) => {
    const updatedOutcomes = rule.outcomes.map(o => o.feId == outcome.feId ? outcome : o);
    const updatedRule = {
      ...rule,
      outcomes: updatedOutcomes
    }
    setRule(updatedRule);
  }

  const tabName = tabPane == RuleFor.OpportunitySync
    ? 'Opportunity Sync'
    : tabPane == RuleFor.CalendarDisplay
      ? 'Calendar Display'
      : 'Slot Booking';

  const getRuleMethodOptions = (ruleFor: RuleFor) => {
    const options = [
      { label: 'Opportunity Sync', value: MethodName.SYNC_OPPORTUNITY, style: {} },
      { label: 'SST Calendar', value: MethodName.GET_SST, style: {} },
      { label: 'Agents Calendar', value: MethodName.GET_CALENDAR, style: {} },
      { label: 'SST Booking', value: MethodName.BOOK_SST, style: {} },
      { label: 'Agents Booking', value: MethodName.BOOK_CALENDAR, style: {} },
    ]

    switch (ruleFor) {
      case RuleFor.OpportunitySync:
        options
          .filter(option => option.value !== MethodName.SYNC_OPPORTUNITY)
          .map(option => option.style = { display: 'none' });
        break;
      case RuleFor.CalendarDisplay:
        options
          .filter(option => option.value !== MethodName.GET_SST && option.value !== MethodName.GET_CALENDAR)
          .map(option => option.style = { display: 'none' });
        break;
      case RuleFor.SlotBooking:
        options
          .filter(option => option.value !== MethodName.BOOK_SST && option.value !== MethodName.BOOK_CALENDAR)
          .map(option => option.style = { display: 'none' });
        break;
      default:
    }

    return options;
  };

  const onMethodNameChange = (value: MethodName[], ruleFor: RuleFor) => {
    const updatedConditions = rule.conditions.map(condition => {
      if (condition.ruleFor === ruleFor) {
        return {
          ...condition,
          methodNames: value
        };
      }
      return condition;
    });
    const updatedOutcomes = rule.outcomes.map(outcome => {
      if (outcome.ruleFor === ruleFor) {
        return {
          ...outcome,
          methodNames: value
        };
      }
      return outcome;
    });

    const updatedRule = {
      ...rule,
      conditions: updatedConditions,
      outcomes: updatedOutcomes
    };
    setRule(updatedRule);
  }

  const ruleActionOptions = (ruleFor: RuleFor) => {
    switch (ruleFor) {
      case RuleFor.OpportunitySync:
        return [RuleAction.SetIsDeleted]
      case RuleFor.CalendarDisplay:
        return [RuleAction.SetHoursInAdvance, RuleAction.SetDoNotCallBefore, RuleAction.UseSellerIds, RuleAction.UseSellerProducts]
      case RuleFor.SlotBooking:
        return [RuleAction.UseSellerIds, RuleAction.UseSellerProducts]
    }
  }

  const getDynamicOutcomeValue = (outcome: CustomerRuleOutcome) => {
    switch (outcome.action) {
      case RuleAction.SetHoursInAdvance:
        return <Input
          placeholder="Value"
          value={outcome.actionValue}
          type="number"
          onChange={(e) => { outcome.actionValue = e.target.value.toString(); updateOutcome(outcome) }}
        />
      case RuleAction.SetDoNotCallBefore:
        return <DateTimePicker
          placeholder="Pick date and time"
          valueFormat="YYYY MMM DD HH:mm"
          popoverProps={{ zIndex: 10000 }}
          minDate={dayjs().utc().add(-1, 'day').toDate()}
          timeInputProps={{ step: 900 }}
          value={dayjs(outcome.actionValue).isValid() ? dayjs(outcome.actionValue).toDate() : null}
          onChange={(value) => {
            if (dayjs(value).isValid()) {
              outcome.actionValue = dayjs(value).utc().format();
              updateOutcome(outcome);
            }
          }}
          renderDay={(date) => {
            const day = date.getDay();
            const isSaturday = day === 6;
            return (
              <div
                style={{
                  color: isSaturday ? 'black' : 'inherit',
                }}
              >
                {date.getDate()}
              </div>
            );
          }}
        />
      case RuleAction.UseSellerIds:
        return <TextArea
          placeholder="Value"
          value={outcome.actionValue}
          rows={1}
          onChange={(e) => { outcome.actionValue = e.target.value; updateOutcome(outcome) }}
        />
      case RuleAction.UseSellerProducts:
        return <TextArea
          placeholder="Value"
          value={outcome.actionValue}
          rows={1}
          onChange={(e) => { outcome.actionValue = e.target.value; updateOutcome(outcome) }}
        />
      case RuleAction.SetIsDeleted:
        return (
          <Select
            placeholder="Value"
            value={outcome.actionValue === 'true'}
            onChange={(value) => { outcome.actionValue = value.toString(); updateOutcome(outcome) }}
          >
            <Option value="true">True</Option>
            <Option value="false">False</Option>
          </Select>
        );
      default:
        return null;
    }
  }

  const onSelectActionChange = (value: RuleAction, outcome: CustomerRuleOutcome) => {
    const updatedAction = {
      ...outcome,
      action: value,
      actionValue: ''
    };
    updateOutcome(updatedAction);
  }
  return (
    <div className="bg-gray-50 p-4 rounded-md">
      <h4>Rules {tabName}</h4>

      {rule.conditions.filter(x => x.ruleFor == tabPane).length > 0 &&
        <Form.Item
          label="Method Types"
          name={['methodNames', rule.conditions.filter(x => x.ruleFor == tabPane)[0]?.feId]}
          rules={[{ required: true, message: 'Please select a method type!' }]}
          initialValue={rule.conditions.filter(x => x.ruleFor == tabPane)[0]?.methodNames ? rule.conditions.filter(x => x.ruleFor == tabPane)[0].methodNames : []}
        >
          <CheckboxGroup
            options={getRuleMethodOptions(tabPane)}
            value={rule.conditions.filter(x => x.ruleFor == tabPane)[0]?.methodNames ? rule.conditions.filter(x => x.ruleFor == tabPane)[0].methodNames : []}
            onChange={(value) => onMethodNameChange(value, tabPane)} />
        </Form.Item>
      }
      {rule.conditions
        ?.filter(condition => condition.ruleFor == tabPane)
        ?.map((condition, index) => (
          <Space key={condition.feId} align="baseline" className="w-full">
            <Form.Item>
              <h4>{index == 0 ? 'IF' : 'AND'}</h4>
            </Form.Item>

            <Form.Item
              name={['opportunitySelectCondition', condition.feId]}
              rules={[{ required: true, message: 'Please select a condition!' }]}
              initialValue={condition.propertyName}
            >
              <Select
                style={{ width: 200 }}
                onChange={(value) => { condition.propertyName = value; updateCondition(condition) }}
                value={condition.propertyName}
                placeholder="Opportunity Value">
                {Object.keys(opportunityValues)
                  .map((key: string) => (
                    <Option key={key} value={key}>
                      <Tooltip title={opportunityValues[key]}>
                        <span
                          style={{ display: 'inline-block', width: '100%' }}>
                          {opportunityValues[key]}
                        </span>
                      </Tooltip>
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              name={['conditionActionSelect', condition.feId]}
              rules={[{ required: true, message: 'Please select a condition action!' }]}
              initialValue={condition.condition == RuleCondition.None ? null : condition.condition}
            >
              <Select
                onChange={(value) => { condition.condition = value; updateCondition(condition) }}
                value={condition.condition == RuleCondition.None ? null : condition.condition}
                style={{ width: 120 }}>
                {Object.entries(RuleCondition)
                  .filter(([key, value]) => key != RuleCondition.None)
                  .map(([key, value]) => (
                    <Option key={key} value={value}>
                      {key}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={['conditionValue', condition.feId]}
              rules={[{ required: true, message: 'Please select a condition value!' }]}
              initialValue={condition.value}
            >
              <Input
                style={{ width: 200 }}
                value={condition.value}
                placeholder="Value"
                onChange={(e) => {
                  condition.value = e.target.value;
                  updateCondition(condition);
                }} />
            </Form.Item>
            <Button onClick={() => removeCondition(condition.feId)}>Delete</Button>
          </Space>
        ))
      }
      <Button type="dashed" block className="my-4" onClick={() => addCondition(tabPane)}>
        + Add Condition
      </Button>

      <h4>Outcomes</h4>

      {rule?.outcomes
        ?.filter(outcome => outcome.ruleFor == tabPane)
        ?.map((outcome) => (
          <Space key={outcome.feId} align="start" className="w-full">
            <Form.Item
              name={['outComeActionSelect', `${outcome.feId}`]}
              rules={[{ required: true, message: 'Please select an outcome action!' }]}
              style={{ marginLeft: 10, width: 300, marginTop: 5 }}
              initialValue={outcome.action == RuleAction.None ? null : outcome.action}
            >
              <Select
                onChange={(value) => onSelectActionChange(value, outcome)}
                value={outcome.action == RuleAction.None ? null : outcome.action}
              >
                {ruleActionOptions(tabPane)
                  .map((value) => (
                    <Option key={value} value={value}>
                      {value}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={['outcomeValue', `${outcome.feId}`]}
              initialValue={outcome.actionValue}
              style={{ width: 300, marginTop: 5 }}
              getValueProps={(value) => {
                const valueToReturn = (isNaN(Number(outcome.actionValue)) && dayjs(outcome.actionValue).isValid())
                  ? dayjs(outcome.actionValue).toDate()
                  : outcome.actionValue;
                return { value: valueToReturn };
              }}
              rules={[{ required: true, message: 'Please input the do outcome action!' }]}
            >
              {getDynamicOutcomeValue(outcome)}
            </Form.Item>

            <Button onClick={() => removeOutcome(outcome.feId)}>Delete</Button>
          </Space>
        ))}
      <Button type="dashed" block className="my-4" onClick={() => addOutcome(tabPane)}>
        + Add Outcome
      </Button>
    </div>
  )
};