import { ReactNode, useEffect, useState } from 'react';
import './header.css';
import { GetAccessToken, HasRole, Role } from '../../utils/auth-utils';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Avatar, Dropdown, Select, MenuProps, Button, notification } from 'antd';
import { MenuOutlined, RiseOutlined, SmileOutlined, TrophyOutlined } from '@ant-design/icons';
import { scopes } from '../../authConfig';
import { strings } from '../../lang';
import { datadogRum } from '@datadog/browser-rum';
import { useHeaderState } from '../../store/header-state';
import Link from 'antd/es/typography/Link';
import { UserStatsDto } from '../../dto/reporting-models';
import { reportingService } from '../../services/reporting-service';
import { useShallow } from 'zustand/shallow'

interface HeaderProps {
  optionalComponent?: ReactNode;
  centerComponents?: ReactNode;
}

export const Header = ({ optionalComponent, centerComponents }: HeaderProps) => {
  const avatarColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
  const { instance, inProgress, accounts } = useMsal();
  const [accountMenuItems, setMenuItems] = useState<any>();
  const [userInitials, setUserInitials] = useState<string>();
  const [color, setColor] = useState(avatarColorList[0]);
  const isAuthenticated = useIsAuthenticated();
  const setLang = useHeaderState(useShallow((state) => state.setLanguage));
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isInboundAgent, setIsInboundAgent] = useState(false);
  const [isReportingAgent, setIsReportingAgent] = useState(false);
  const [userInfo, setUserInfo] = useState<UserStatsDto | null>(null);
  const [newUserInfo, setNewUserInfo] = useState<UserStatsDto | null>(null);

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      datadogRum.setUser({
        id: accounts[0].homeAccountId,
        name: accounts[0].name,
        email: accounts[0].username,
      });
      setIsAdmin(HasRole(accounts, Role.BookingAdmin));
      setIsReportingAgent(HasRole(accounts, Role.LiveReporter));
      setIsManager(HasRole(accounts, Role.BookingManager));
      setIsInboundAgent(HasRole(accounts, Role.InboundAgent));
    }
  }, [accounts]);

  useEffect(() => {
    if (HasRole(accounts, Role.BookingAdmin) || HasRole(accounts, Role.LiveReporter)) {
      const intervalId = setInterval(() => {
        getUserActionsAsync();
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    if (isReportingAgent) {
      if (userInfo === null) {
        setUserInfo(newUserInfo);
      } else {
        // get diff between actions and userActions by when and show message for every diff
        if (userInfo?.userActions && newUserInfo?.userActions) {
          const sortedUserActions = userInfo.userActions.sort((a, b) => new Date(b.when).getTime() - new Date(a.when).getTime());
          const oldLatestActionDate = sortedUserActions[0];
          const newActions = oldLatestActionDate ? newUserInfo?.userActions?.filter((x) => new Date(x.when) > new Date(oldLatestActionDate.when) && x.points > 0) : newUserInfo?.userActions;
          for (const action of newActions!) {
            openActionNotification(strings.reportingNotificationActionTitle, `+${action.points}XP ${action.actionType}`);
          }
        }

        if (userInfo?.currentLevel !== newUserInfo?.currentLevel) {
          openLvlNotification('Level up!', strings.reportingNotificationLevelUp + ` ${newUserInfo?.currentLevel} (${newUserInfo?.levelName})`);
        }

        if (userInfo?.slotsForToday && newUserInfo?.slotsForToday) {
          const oldSlots = userInfo?.slotsForToday?.filter((slot) => slot.status === 'Completed');
          const newSlots = newUserInfo?.slotsForToday?.filter((slot) => slot.status === 'Completed');
          let diff = newSlots?.length! - oldSlots?.length!;
          while (diff > 0) {
            openSlotNotification(strings.reportingNotificationCustomerShowedTitle, strings.reportingNotificationCustomerShowed);
            diff--;
          }
        }

        setUserInfo(newUserInfo!);
      }
    }
  }, [newUserInfo]);

  useEffect(() => {
    if (isAuthenticated) {
      GetAccessToken(instance, inProgress, scopes.salesAppointmentApi).then(async (token) => {
        if (token) {
          let firstLetter = 'U';
          try {
            const initials = token.account!.name!.split(/ /g);
            firstLetter = initials[0][0];
            setUserInitials(`${initials[0][0]} ${initials[1][0]}`);

            const codePoint = firstLetter.charCodeAt(0);
            const index = codePoint % avatarColorList.length;
            setColor(avatarColorList[index]);
          } catch (e) {
            setUserInitials('U');
          }

          setMenuItems([
            {
              key: '1',
              label: <Link href="/profile">{token.account.name}</Link>,
              disabled: !(isAdmin || isReportingAgent),
            },
            {
              key: '2',
              label: 'Logout',
              onClick: () => {
                instance.logoutRedirect({
                  account: instance.getActiveAccount(),
                });
              },
            },
          ]);
        }
      });
    }
  }, [isAuthenticated, isAdmin, isReportingAgent]);

  const openSlotNotification = (title: string, description: string) => {
    notification.open({
      message: title,
      description: description,
      showProgress: true,
      icon: <TrophyOutlined style={{ color: '#FFB302' }} />,
    });
  };

  const openActionNotification = (title: string, description: string) => {
    notification.open({
      message: title,
      description: description,
      showProgress: true,
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  };

  const openLvlNotification = (title: string, description: string) => {
    notification.open({
      message: title,
      description: description,
      showProgress: true,
      icon: <RiseOutlined style={{ color: '#108ee9' }} />,
    });
  };

  const getUserActionsAsync = async () => {
    if (isAuthenticated) {
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      if (token) {
        try {
          const actions = await reportingService.getUserDataAsync(true, token.accessToken);
          setNewUserInfo(actions);
        } catch (e) {
          //console.log('Error getting user');
        }
      }
    }
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Link href="/booking">Booking</Link>,
    },
  ];
  if (isAdmin) {
    //|| isReportingAgent) {
    items.push({
      key: '3',
      label: <Link href="/profile">Profile</Link>,
    });
  }
  if (isAdmin || isManager || isReportingAgent) {
    items.push({
      key: '2',
      label: <Link href="/reporting">Reporting</Link>,
    });
  }
  if (isAdmin || isInboundAgent) {
    items.push({
      key: '5',
      label: <Link href="/inbound">Inbound Center</Link>,
    });
  }
  if (isManager || isAdmin) {
    items.push(
      {
        key: '4',
        label: <Link href="/call-list">Call List</Link>,
      },
      {
        key: '7',
        label: <Link href="/conflicts">Conflicts</Link>,
      }
    );
  }
  if (isAdmin) {
    items.push(
      {
        key: '6',
        label: <Link href="/bulk-import-opportunities">Bulk Import Opportunities</Link>,
      },
      {
        key: '8',
        label: <Link href="/admin">Admin Page</Link>,
      }
    );
  }

  return (
    <>
      <header className="header">
        <div className="header__logo">
          <Dropdown menu={{ items }}>
            <Button icon={<MenuOutlined />} />
          </Dropdown>
          <img className="header__img" src="/logo-v2.png" alt="booking-os" />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>{centerComponents}</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {optionalComponent}
          <Select
            className="header__lang"
            defaultValue={['de', 'en'].includes(strings.getLanguage()) ? strings.getLanguage() : 'de'}
            options={[
              { value: 'de', label: 'DE' },
              { value: 'en', label: 'EN' },
            ]}
            onChange={(lang) => {
              document.documentElement.lang = lang;
              strings.setLanguage(lang);
              setLang(lang);
            }}
          ></Select>
          <Dropdown
            className="header__avatar"
            menu={{
              items: accountMenuItems,
              selectable: true,
              defaultSelectedKeys: ['3'],
            }}
          >
            <Avatar className="header__avatar-icon" style={{ backgroundColor: color, verticalAlign: 'middle' }} size="large">
              {userInitials}
            </Avatar>
          </Dropdown>
        </div>
      </header>
    </>
  );
};
