import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Row
} from 'antd';
import { CustomerRule, MethodName } from '../../../../dto/admin-rule-models';
import { GetAccessToken } from '../../../../utils/auth-utils';
import { useMsal } from '@azure/msal-react';
import { scopes } from '../../../../authConfig';
import { CustomerRuleCard } from './customer-rule-card';
import { CustomerRuleForm } from './customer-rule-form';
import { getCustomerRules, postCustomerRules } from '../../../../services/admin-page-service';
import { SalesChannel } from '../../../../dto/model';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;


export const AdminCustomerRule = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [customerRules, setCustomerRules] = useState<CustomerRule[]>([]);
  const [displayRuleForm, shouldDisplayRuleForm] = useState<boolean>(false);
  const [displayAddButton, shouldDisplayAddButton] = useState<boolean>(true);
  useEffect(() => {
    const fetchCustomerRules = async () => {
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      const customerRules = await getCustomerRules(token.accessToken);
      if (customerRules.isSuccess) {
        setCustomerRules(customerRules.data!);
      }
    };
    fetchCustomerRules();
  }, []);

  const onSubmit = async (rule: CustomerRule) => {
    const updatedRules = customerRules.map((r) => {
      if (r.feId === rule.feId) {
        return rule;
      }
      return r;
    });

    const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
    await postCustomerRules(token.accessToken, rule);

    setCustomerRules(updatedRules);
    shouldDisplayRuleForm(false);
    shouldDisplayAddButton(true);

    //await to fetch the rules
  };

  const onCancel = () => {
    const updatedRules = customerRules.filter((r) => r.id !== 0);
    shouldDisplayRuleForm(false);
    shouldDisplayAddButton(true);
    setCustomerRules(updatedRules);
  };

  const addCustomerRule = () => {
    const newRule = {
      id: 0,
      feId: crypto.randomUUID(),
      title: '',
      description: '',
      createdDate: new Date(),
      lastModifiedDate: new Date(),
      createdBy: '',
      isDeleted: false,
      methodNames: [MethodName.NONE],
      salesChannels: [SalesChannel.MissingQueue],
      conditions: [],
      outcomes: [],
    }
    setCustomerRules([...customerRules, newRule]);
    shouldDisplayRuleForm(true);
    shouldDisplayAddButton(false);
  };

  return (
    <div
      className="container flex-admin-vertical-container"
      style={{ width: 900 }}
    >
      <Row justify="space-between" align="bottom" style={{ marginBottom: '20px' }}>
        <Title level={4} style={{ marginBottom: 0 }}>Customer Journey Rules</Title>
        {displayAddButton &&
          <Button type="primary" onClick={addCustomerRule} icon={<PlusOutlined />}>
            Add Rule
          </Button>}
      </Row>
      {displayRuleForm && <CustomerRuleForm rule={customerRules.find(x => x.id == 0)!} onSubmit={onSubmit} onCancel={onCancel} />}
      {!displayRuleForm && customerRules
        .filter((rule) => !rule.isDeleted)
        .map((rule) => (
          <CustomerRuleCard rule={rule} onSubmit={onSubmit} shouldDisplayAddButton={shouldDisplayAddButton} />
        ))}
    </div>
  );
};