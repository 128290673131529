import { BookingConfiguration } from '../dto/admin-config-models';
import { CustomerRule, MethodName } from '../dto/admin-rule-models';
import { Result, SalesChannel, SlotsWithSalesManager } from '../dto/model';

const rootUrl = (process as any).env.REACT_APP_API_ROOT_URL;

export async function getConfigurations(
  accessToken: string,
  salesChannel: SalesChannel
): Promise<BookingConfiguration[] | null> {
  try {
    const response = await fetch(
      `${rootUrl}/api/v1/admin/configurations?salesChannel=${salesChannel}`,
      {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
        }),
      }
    );
    const data = await response.json();
    return data; // Add this line to return the data
  } catch (error) {
    alert(`Failed to get configuration. Exception while loading ${error}`);
  }
  return null;
}

export async function postConfigurations(
  accessToken: string,
  payload: BookingConfiguration[]
): Promise<Result<number>> {
  try {
    const response = await fetch(`${rootUrl}/api/v1/admin/configurations`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(payload),
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return {
      data: 0,
      errors: ['Failed to save configuration'],
      isSuccess: false,
    };
  }
}

export async function getCustomerRules(
  accessToken: string
): Promise<Result<CustomerRule[] | null>> {
  try {
    const url = `${rootUrl}/api/v1/opportunity/rules`;
    const response = await fetch(
      url,
      {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
        }),
      }
    );
    const data = await response.json() as Result<CustomerRule[]>;

    if (data.isSuccess) {
      data.data?.map((x) => (x.feId = crypto.randomUUID()));
      data.data?.map((x) => (x.conditions.map((y) => y.feId = crypto.randomUUID())));
      data.data?.map((x) => (x.outcomes.map((y) => y.feId = crypto.randomUUID())));
    }

    return data; // Add this line to return the data
  } catch (error) {
    alert(`Failed to get configuration. Exception while loading ${error}`);
    return {
      data: null,
      errors: [`${JSON.stringify(error)}`],
      isSuccess: false,
    }
  }
}

export async function postCustomerRules(
  accessToken: string,
  payload: CustomerRule
): Promise<Result<number>> {
  try {
    const url = `${rootUrl}/api/v1/opportunity/rules`;
    const response = await fetch(
      url,
      {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(payload),
      }
    );
    const data = await response.json();

    return data; // Add this line to return the data

  } catch (error) {
    alert(`Failed to get configuration. Exception while loading ${error}`);
    return {
      data: -1,
      errors: [`${JSON.stringify(error)}`],
      isSuccess: false,
    }
  }
}

export async function getManagerWithSlots(
  accessToken: string,
  startDate: string,
  endDate: string
): Promise<Result<SlotsWithSalesManager[]>> {
  try {
    const response = await fetch(
      `${rootUrl}/api/v1/analytics/slot?startDate=${startDate}&endDate=${endDate}`,
      {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
        }),
      }
    );
    const data = await response.json();
    return data as Result<SlotsWithSalesManager[]>; // Add this line to return the data
  } catch (error) {
    alert(`Failed to get configuration. Exception while loading ${error}`);
    return {
      data: [],
      errors: [`${JSON.stringify(error)}`],
      isSuccess: false,
    }
  }
}