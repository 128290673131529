import './case-list-page.css';
import { useEffect, useState } from "react";
import { Header } from "../header/header";
import { TotalOpportunities } from "../../dto/model";
import { useMsal } from "@azure/msal-react";
import { scopes } from "../../authConfig";
import { GetAccessToken } from "../../utils/auth-utils";
import { bookingPageService } from "../../services/booking-page-service";
import { Button, Skeleton, Table, Statistic } from "antd";
import { handleDownloadAll } from '../../utils/file-utils';

export const CaseListPage = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [totalOpportunities, setTotalOpportunities] = useState<TotalOpportunities>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const token = await GetAccessToken(
        instance,
        inProgress,
        scopes.salesAppointmentApi,
      );
      const totalOpps = await bookingPageService.getAllCases(token!.accessToken, true);
      setTotalOpportunities(totalOpps);
    })();
  }, []);

  const columns = [
    { dataIndex: "id", title: "Opportunity Id" },
    { dataIndex: "bookingStatus", title: "Booking Status" },
    { dataIndex: "callCounter", title: "Call Count" },
    { dataIndex: "notReachedCounter", title: "Not Reached Count" },
    { dataIndex: "doNotCallBefore", title: "Do Not Call Before" },
  ];

  const downloadOpps = async () => {
    setIsLoading(true);
    const token = await GetAccessToken(
      instance,
      inProgress,
      scopes.salesAppointmentApi,
    );

    const totalOps = await bookingPageService.getAllCases(token!.accessToken, false);
    handleDownloadAll(totalOps.opportunities, 'opportunity_queue_bookingos');
    setIsLoading(false);
  }

  return (
    <>
      <Header />
      <div className="container container--large">
        <div className="clp-horizontal-div">
          <Statistic title="Number of Callable Opportunities" value={totalOpportunities ? totalOpportunities.count : 0} valueStyle={{ fontSize: 16 }} style={{ alignSelf: 'center', margin: 5 }} />
          <Button className="clp-download-btn" onClick={downloadOpps} loading={isLoading}>Download All</Button>
        </div>
        {!totalOpportunities && <Skeleton />}
        {totalOpportunities && (
          <Table
            columns={columns}
            dataSource={totalOpportunities.opportunities.map((data, index) => ({
              ...data,
              key: data.opportunityId,
            }))}
          />
        )}
      </div>
    </>
  );
};
