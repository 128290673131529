import { useState } from "react";
import { DateTimePicker } from "@mantine/dates";
import { Button, notification, Row } from "antd";
import { GetAccessToken } from "../../../../utils/auth-utils";
import { scopes } from "../../../../authConfig";
import { handleDownloadAll } from "../../../../utils/file-utils";
import { getManagerWithSlots } from "../../../../services/admin-page-service";
import { useMsal } from "@azure/msal-react";
import dayjs from "dayjs";

export const AnalyticsDownload = () => {
  const [startDateTime, setStartDateTime] = useState<string>('');
  const [endDateTime, setEndDateTime] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { instance, inProgress, accounts } = useMsal();

  const downloadSlotsAndManagers = async () => {
    if (startDateTime === '' || endDateTime === '') {
      notification.error({ message: 'Please select start and end time' });
      return;
    }
    setIsLoading(true);
    const token = await GetAccessToken(
      instance,
      inProgress,
      scopes.salesAppointmentApi,
    );

    const totalOps = await getManagerWithSlots(token!.accessToken, startDateTime, endDateTime);
    if (!totalOps.isSuccess) {
      notification.error({ message: 'Failed to download slots and managers', description: totalOps?.errors?.join(',') });
      setIsLoading(false);
      return;
    }

    handleDownloadAll(totalOps.data, `slots_${dayjs(startDateTime).format('YYYY-MM-DD HH:mm')}_${dayjs(endDateTime).format('YYYY-MM-DD HH:mm')}`); setIsLoading(false);
  }

  return (
    <div
      className="container flex-admin-vertical-container"
      style={{ width: 900, }}
    >
      <Row>
        <DateTimePicker
          style={{ width: 200, marginRight: 20 }}
          onChange={(value) => setStartDateTime(value?.toISOString() || '')}
          valueFormat="DD MMM YYYY hh:mm A"
          label="Start Time"
          placeholder="Start Time"
        />
        <DateTimePicker
          style={{ width: 200 }}
          onChange={(value) => setEndDateTime(value?.toISOString() || '')}
          valueFormat="DD MMM YYYY hh:mm A"
          label="End Time"
          placeholder="End Time"
        />
      </Row>
      <Button className="clp-download-btn" onClick={downloadSlotsAndManagers} loading={isLoading}>Download All</Button>

    </div>
  );
};