import { CustomerRule, CustomerRuleCondition, CustomerRuleOutcome, RuleFor } from "../../../../dto/admin-rule-models"
import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Tabs,
  Checkbox,
  notification,
} from 'antd';
import { SalesChannel } from "../../../../dto/model";
import { RuleTab } from "./customer-rule-tab";

const CheckboxGroup = Checkbox.Group;
const { Option } = Select;

type CustomerRuleFormProps = {
  rule: CustomerRule
  onSubmit: (rule: CustomerRule) => void
  onCancel: () => void
}

export const CustomerRuleForm = ({ rule, onSubmit, onCancel }: CustomerRuleFormProps) => {
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState(RuleFor.OpportunitySync);
  const [currentRule, setCurrentRule] = useState<CustomerRule>(rule);
  useEffect(() => {
    setCurrentRule(rule);
  }, [rule]);

  const handleTabChange = (key: string) => {
    setActiveTab(key as RuleFor);
  };
  const options = [
    { label: 'Digital Sales', value: SalesChannel.DigitalSales },
    { label: 'CRM Sales', value: SalesChannel.CrmSales },
    { label: 'Revision Queue', value: SalesChannel.Revision },
    { label: 'Sales Call 2', value: SalesChannel.SalesCall2 },
    { label: 'Heat Pump', value: SalesChannel.PumpPv },
  ];
  const onSalesChannelSelect = (value: SalesChannel[]) => {
    setCurrentRule({ ...currentRule, salesChannels: value });
  }

  const onFormSubmit = async () => {
    try {
      await form.validateFields();

      const groupedConditions = currentRule.conditions.reduce((acc, condition) => {
        if (!acc[condition.ruleFor]) {
          acc[condition.ruleFor] = [];
        }
        acc[condition.ruleFor].push(condition);
        return acc;
      }, {} as Record<RuleFor, CustomerRuleCondition[]>);

      const groupedOutcomes = currentRule.outcomes.reduce((acc, outcome) => {
        if (!acc[outcome.ruleFor]) {
          acc[outcome.ruleFor] = [];
        }
        acc[outcome.ruleFor].push(outcome);
        return acc;
      }, {} as Record<RuleFor, CustomerRuleOutcome[]>);

      const ruleForKeys = Object.keys(RuleFor) as (keyof typeof RuleFor)[];

      for (const key of ruleForKeys) {
        const ruleFor = RuleFor[key];
        if (
          (groupedConditions[ruleFor] && groupedConditions[ruleFor].length > 0) ||
          (groupedOutcomes[ruleFor] && groupedOutcomes[ruleFor].length > 0)
        ) {
          if (
            (!groupedConditions[ruleFor] || groupedConditions[ruleFor].length === 0) ||
            (!groupedOutcomes[ruleFor] || groupedOutcomes[ruleFor].length === 0)
          ) {
            notification.error({
              message: 'Validation Error',
              description: 'We need at least one outcome and one condition for' + ruleFor,
            });
            return;
          }
        }
      }

      onSubmit(currentRule);
    }
    catch (e) {
      console.log(e);
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFormSubmit}
    >
      <Form.Item
        label="Title"
        name={['title', rule.feId]}
        initialValue={rule.title}
        rules={[{ required: true, message: 'Please input the title!' }]}
      >
        <Input
          placeholder="Title for the customer rule"
          onChange={(e) => setCurrentRule({ ...currentRule, title: e.target.value })}
          value={currentRule?.title} />
      </Form.Item>

      <Form.Item
        label="Description"
        name={['description', rule.feId]}
        initialValue={rule.description}
        rules={[{ required: true, message: 'Please input the description!' }]}
      >
        <Input.TextArea
          placeholder="Description for your customer rule"
          onChange={(e) => setCurrentRule({ ...currentRule, description: e.target.value })}
          value={currentRule?.description}
          rows={2}
        />
      </Form.Item>
      <Form.Item
        label="Sales Channels"
        name={['salesChannels', rule.feId]}
        rules={[{ required: true, message: 'Please select a sales channel!' }]}
        initialValue={currentRule.salesChannels ? currentRule.salesChannels : []}
      >
        <CheckboxGroup
          options={options}
          value={currentRule.salesChannels ? currentRule.salesChannels : []}
          onChange={onSalesChannelSelect} />
      </Form.Item>

      <Tabs defaultActiveKey={RuleFor.OpportunitySync} onChange={handleTabChange}>
        <Tabs.TabPane tab="Opportunity Sync" key={RuleFor.OpportunitySync}>
          {activeTab === RuleFor.OpportunitySync &&
            <RuleTab rule={currentRule} setRule={setCurrentRule} tabPane={RuleFor.OpportunitySync} />}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Calendar Display" key={RuleFor.CalendarDisplay}>
          {activeTab === RuleFor.CalendarDisplay &&
            <RuleTab rule={currentRule} setRule={setCurrentRule} tabPane={RuleFor.CalendarDisplay} />}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Slot Booking" key={RuleFor.SlotBooking}>
          {activeTab === RuleFor.SlotBooking &&
            <RuleTab rule={currentRule} setRule={setCurrentRule} tabPane={RuleFor.SlotBooking} />}
        </Tabs.TabPane>
      </Tabs>

      <div className="bg-gray-50 p-4 rounded-md" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" htmlType="submit">
          Save Changes
        </Button>
      </div>

    </Form>
  )
};